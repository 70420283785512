import React from "react";
import styled from "styled-components";
import img from "../images/img1.png";
import Hamburg from "./Hamburg";
import { largephone, mobile, tablet } from "./responsive";
import "aos/dist/aos.css";

const Container = styled.div`
  width: 100vw;
  position: fixed;
  z-index: 100;
  border-bottom: 2px solid #d7950c;
  background-color: #100d08;
`;

const Header = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 4rem;

  ${tablet({ padding: "1rem 2rem" })}
  ${largephone({ padding: "1rem 1rem" })}

  & a {
    text-decoration: none;
    color: white;
  }
`;

const Name = styled.h1`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  text-decoration: none;
  transition: all 0.3s ease;
  ${largephone({ fontSize: "1.2rem" })}
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;

  &:hover ${Name} {
    color: #d7950c;
  }

  ${largephone({ gap: 0 })}
`;
const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: contain;
`;

const Right = styled.div`
  ${mobile({ display: "none" })}
`;
const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;

  ${tablet({ gap: "2rem" })}
  ${largephone({ gap: "1rem" })}
`;
const MenuItems = styled.span`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;

  & a {
    text-decoration: none;
    transition: all 0.3s ease;

    ${largephone({ fontSize: "15px" })}

    &:hover {
      color: #d7950c;
      border-bottom: 0.1rem solid #d7950c;
      padding-bottom: 0.5rem;
    }
  }
`;

const Navbar = () => {
  
  return (
    <Container >
      <Header>
        <a href="#home">
          <Left>
            <Logo src={img}></Logo>
            <Name>HEMENDRA B.</Name>
          </Left>
        </a>

        <Right>
          <Menu>
            <MenuItems>
              <a href="#home">HOME</a>
            </MenuItems>
            <MenuItems>
              <a href="#about">ABOUT</a>
            </MenuItems>
            <MenuItems>
              <a href="#projects">PROJECTS</a>
            </MenuItems>
            <MenuItems>
              <a href="#contact">CONTACT</a>
            </MenuItems>
          </Menu>
        </Right>
        <Hamburg />
      </Header>
    </Container>
  );
};

export default Navbar;

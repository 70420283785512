import React from "react";
import styled from "styled-components";
import img from "../images/img6.png";
import img2 from "../images/img3.png";
import { laptop, largephone, mobile, tablet } from "./responsive";
import "aos/dist/aos.css";

const Container = styled.div`
  background-color: black;
  color: white;
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  ${largephone({
    display: "block",
    width: "calc(100vw - 2rem)",
    padding: "0 1rem",
  })}
  ${mobile({ padding: "0 0.5rem", width: "calc(100vw - 1rem)" })}
`;

const FooterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  border-bottom: 1px solid #d7950c;
  margin-bottom: 2rem;

  ${laptop({ width: "70%" })}
  ${largephone({ width: "100%", display: "block", paddingBottom: "2rem" })}
`;

const Content = styled.div`
  padding: 2rem 0;
`;

const Header = styled.h1`
  font-size: 2.5rem;
  color: #d7950c;

  ${tablet({ fontSize: "2.3rem" })}
  ${largephone({ fontSize: "1.9rem" })}
`;

const Contents = styled.p`
  padding-right: 15rem;

  ${tablet({ fontSize: "0.9rem", paddingRight: "1.3rem" })}

  ${laptop({
    paddingRight: "4rem",
  })}
  ${mobile({
    paddingRight: "0rem",
  })}


`;

const Details = styled.div``;

const Email = styled.div`
  align-items: center;
  justify-content: start;
  gap: 1rem;
  display: flex;
  ${largephone({ gap: "0.5rem" })}
`;

const Logo = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
`;

const EmailId = styled.p``;

const Location = styled.div`
  align-items: center;
  gap: 1rem;
  justify-content: start;
  display: flex;
  ${largephone({ gap: "0.5rem" })}
`;

const LocationName = styled.div``;

const CopyrightSecion = styled.div``;

const Copyright = styled.div`
  & a {
    color: #d7950c;
  }

  ${tablet({ fontSize: "0.9rem" })}
  ${largephone({ textAlign: "center" })}
  ${mobile({ fontSize: "0.7rem" })}

`;

const Footer = () => {
  
  return (
    <Container >
      <FooterSection>
        <Content>
          <Header>HEMENDRA BASIYA</Header>
          <Contents>
            A Fullstack WordPress Web Developer building Websites and Web Applications
            that leads to the success of the overall product.
          </Contents>
        </Content>
        <Details>
          <Email>
            <Logo src={img} />
            <EmailId>hemendra.basia@gmail.com</EmailId>
          </Email>
          <Location>
            <Logo src={img2} />
            <LocationName>Rajkot, India</LocationName>
          </Location>
        </Details>
      </FooterSection>

      <CopyrightSecion>
        <Copyright>
          &#169; Copyright 2022. Made by <a href="#home">HEMENDRA BASIYA</a>
        </Copyright>
      </CopyrightSecion>
    </Container>
  );
};

export default Footer;

import React from "react";
import styled from "styled-components";
import { laptop, largephone, mobile, tablet } from "./responsive";
import Aos from "aos";
import { useEffect } from "react";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8rem;
  background-color: #100d08;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='551' height='66.1' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23222' stroke-width='8.7' stroke-opacity='0.24'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");

  ${tablet({ marginBottom: 0 })}
`;
const MainContent = styled.div`
  max-width: 60%;
  min-height: 30%;

  ${tablet({ maxWidth: "75%" })}
  ${largephone({ maxWidth: "85%", minHeight: "25%" })}
  ${mobile({ maxWidth: "95%" })}
`;
const Header = styled.h1`
  font-size: 4rem;
  text-align: center;
  margin: 0;

  ${laptop({ fontSize: "3.5rem" })}
  ${tablet({ fontSize: "3.2rem" })}
  ${largephone({ fontSize: "2.6rem" })}
  ${mobile({ fontSize: "2rem" })}
`;
const Content = styled.p`
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0 6.5rem;
  margin: 0;

  ${laptop({ fontSize: "1.2rem" })}
  ${tablet({ fontSize: "1rem", padding: 0 })}
${mobile({ lineHeight: "1.5rem" })}




  & span {
    color: #d7950c;
  }
`;
const Button = styled.div`
  text-align: center;
  margin-top: 3rem;

  & a {
    font-size: 1.5rem;
    text-decoration: none;
    color: black;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #d7950c;
    padding: 0.8rem 4.5rem;
    border-radius: 5px;
    transition: all 0.3s ease;

    &:hover {
      color: black;
    }
  }
`;

const Main = () => {
  useEffect(() => {
    Aos.init({ once: true, duration: 1500 });
  }, []);
  return (
    <Container id="home">
      <MainContent data-aos="zoom-in">
        <Header>HEY, I'M HEMENDRA BASIYA</Header>
        <Content>
          A <span>Fullstack WordPress Web Developer</span> building Websites and Web
          Applications that leads to the success of the overall product.
        </Content>
        <Button>
          <a href="#projects">PROJECTS</a>
        </Button>
      </MainContent>
    </Container>
  );
};

export default Main;

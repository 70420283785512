import React from "react";
import styled from "styled-components";
import img1 from "../images/img2.png";
import img2 from "../images/img13.png";
import { laptop, largephone, mobile, tablet } from "./responsive";
import "aos/dist/aos.css";

const Container = styled.div`
  display: flex;
  max-width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${tablet({ padding: "0 2rem" })}
  ${mobile({ padding: "0 0.5rem" })}
`;

const Heading = styled.h1`
  font-size: 3.5rem;
  margin: 10rem 0;
  position: relative;

  ${mobile({ fontSize: "2.5rem" })}

  &::after {
    content: "";
    width: 4rem;
    height: 8px;
    background-color: #d7950c;
    position: absolute;
    border-radius: 5px;
    left: calc(50% - 1.5rem);
    top: 100%;
  }
`;

const ProjectContainer = styled.div`
  max-width: 70rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  gap: 4.5rem;

  ${laptop({ gap: "2rem" })};
  ${largephone({
    flexDirection: "column",
    paddingTop: "5rem",
    border: "1.5px solid white",
    marginBottom: "3rem",
  })}
`;

const ImageContainer = styled.div`
  flex: 1;
  min-height: 35rem;

  ${tablet({ minHeight: "30rem" })}
  ${mobile({ minHeight: "15rem" })}
`;

const Image = styled.img`
  max-width: 100%;
  object-fit: contain;

  ${laptop({ maxWidth: "90%" })}
  ${largephone({ display: "block", margin: "auto", maxWidth: "75%" })}
`;

const ContentSection = styled.div`
  min-height: 35rem;
  flex: 1;
  ${laptop({ padding: "0 2rem" })}
  ${tablet({ minHeight: "30rem" })}
`;

const Header = styled.h1`
  font-size: 2.5rem;
  margin: 0;

  ${tablet({ lineHeight: "2rem" })}
  ${mobile({ fontSize: "2rem" })}
`;

const Content = styled.p`
  font-size: 1.2rem;
  margin: 2rem 0;
  text-align: justify;
  ${mobile({ fontSize: "1rem" })}
`;

const Button = styled.div`
  ${largephone({ textAlign: "center" })}

  & a {
    font-size: 1.5rem;
    color: black;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #d7950c;
    border-radius: 5px;
    text-decoration: none;
    padding: 0.8rem 4.5rem;
  }
`;

const Projects = () => {
  
  return (
    <Container id="projects" data-aos='zoom-in' >
      <Heading>PROJECTS</Heading>
      <ProjectContainer data-aos='zoom-in'>
        <ImageContainer>
          <Image src={img1}></Image>
        </ImageContainer>
        <ContentSection>
          <Header>AD Custom</Header>
          <Content>
            AD Custom is a motorcycle customization & accessories shop located
            in Rajkot.
          </Content>
          <Button>
            <a href="https://www.adcustoms.in/" target="_blank">
              OPEN
            </a>
          </Button>
        </ContentSection>
      </ProjectContainer>
      <ProjectContainer data-aos='zoom-in'>
        <ImageContainer>
          <Image src={img2}></Image>
        </ImageContainer>
        <ContentSection>
          <Header>Saurashtra Gramin Bank</Header>
          <Content>
            I re-created the frontend of Saurashtra Gramin Bank's official web
            app because I got attracted to their beautiful UI. It was a great
            experience for me to build the entire frontend.
          </Content>
          <Button>
            <a href="https://venerable-pudding-bcff53.netlify.app" target="_blank">
              OPEN
            </a>
          </Button>
        </ContentSection>
      </ProjectContainer>
      
    </Container>
  );
};

export default Projects;

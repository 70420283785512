import React from "react";
import styled from "styled-components";
import { laptop, largephone, mobile, tablet } from "./responsive";
import "aos/dist/aos.css";

const Container = styled.div`
  display: flex;
  max-width: 100vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;

${tablet({marginBottom: 0, padding: '0 1rem'})}
${mobile({padding: '0 0.5rem'})}

`;
const Heading = styled.h1`
  font-size: 3.5rem;
  margin: 10rem 0;
  position: relative;
${laptop({fontSize: '3rem'})}
${mobile({fontSize: '2.5rem', margin: '7rem 0'})}



  &::after {
    content: "";
    width: 4rem;
    height: 8px;
    background-color: #d7950c;
    position: absolute;
    border-radius: 5px;
    left: calc(50% - 1.5rem);
    top: 100%;
  }
`;
const Main = styled.div`
  max-width: 65%;
  display: flex;
  justify-content: center;
  gap: 5rem;
  align-items: center;

${laptop({maxWidth: '80%'})}
${tablet({maxWidth: '95%', gap: '3rem'})}
${largephone({flexDirection: 'column'})}
${mobile({gap: '7rem'})}


`;
const Content = styled.div`
  flex: 1;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  

${largephone({minHeight: '45vh'})}

`;
const Header = styled.h1`
  font-size: 2.5rem;

${laptop({fontSize: '2.2rem'})}

  & span {
    color: #d7950c;
  }
`;
const Contents = styled.p`
  font-size: 1.2rem;
  margin: 2rem 0;
  text-align: justify;

${laptop({fontSize: '1rem'})}


  & span {
    color: #d7950c;
  }
`;
const Button = styled.div`
  & a {
    font-size: 1.5rem;
    color: black;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: #d7950c;
    border-radius: 5px;
    text-decoration: none;
    padding: 0.8rem 4.5rem;
  }
`;
const SkillSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 60vh;
  flex: 1;

${largephone({minHeight: '45vh'})}

`;
const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;

  ${mobile({gap: '0.5rem'})}
`;
const SkillHeader = styled.h1`
  font-size: 2.5rem;

${laptop({fontSize: '2.2rem'})}

  & span {
    color: #d7950c;
  }

`;
const Skills = styled.p`
  font-size: 1rem;
  background-color: #000000;
  border-radius: 5px;
  padding: 1rem 2rem;
  color: #d7950c;
  font-weight: 600;
  margin: 0;

  ${mobile({fontSize: '0.8rem', padding: '0.5rem 1rem'})}
  
`;

const About = () => {
  
  return (
    <Container id="about" data-aos='zoom-in' >
      <Heading>ABOUT ME</Heading>

      <Main data-aos='zoom-in'>
        <Content>
          <Header>Get to know me<span>!</span></Header>
          <Contents>
            I'm a Fullstack Web Developer building Websites and Web Applications
            focused on <span>ReactJS, NodeJS, Express, MongoDB and WordPress</span> that
            leads to the success of the overall product. I'm passionate about
            learning new technologies and improving myself in Web Development.
            <br />
            <br />
            Check out some of my work in the Projects section.
            <br />
            <br /> I'm open to Job opportunities where I can contribute, learn
            and grow. If you have a good opportunity that matches my skills then
            feel free to contact me. Also I like to work in my own environment
            so i prefer to work remotely.
          </Contents>
          <Button>
            <a href="#contact">Contact</a>
          </Button>
        </Content>
        <SkillSection>
          <SkillHeader>My Skills<span>:</span></SkillHeader>
          <SkillsContainer>
            <Skills>HTML</Skills>
            <Skills>CSS</Skills>
            <Skills>JAVASCRIPT</Skills>
            <Skills>REACT</Skills>
            <Skills>NODE</Skills>
            <Skills>EXPRESS</Skills>
            <Skills>MONGODB</Skills>
            <Skills>WORDPRESS</Skills>
            <Skills>PHP</Skills>
            <Skills>GITHUB</Skills>
            <Skills>SEO</Skills>
            <Skills>RESPONSIVE DESIGNS</Skills>
            <Skills>BOOTSTRAP</Skills>
          </SkillsContainer>
        </SkillSection>
      </Main>
    </Container>
  );
};

export default About;

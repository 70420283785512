import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer.jsx";
import Main from "./components/Main";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewPage from "./components/NewPage";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Navbar />
                <Main />
                <About />
                <Projects />
                <Contact />
                <Footer />
              </>
            }
          />
          <Route path="newpage" element={<NewPage/>}  />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

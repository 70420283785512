import React from "react";
import styled from "styled-components";
import { largephone, mobile } from "./responsive";
import "aos/dist/aos.css";

const Container = styled.div`
  padding-bottom: 5rem;
  width: 100vw;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='551' height='66.1' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23222' stroke-width='8.7' stroke-opacity='0.24'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
`;

const MainContainer = styled.div`
  padding: 2rem;
  width: 45rem;
  margin: auto;

  ${largephone({padding: '1rem', width: '30rem'})}
  ${mobile({padding: 0, width: '16.5rem'})}

`;

const Header = styled.div`
  margin-bottom: 5rem;

  ${mobile({marginBottom: '2rem'})}
`;

const Heading = styled.h1`
  text-align: center;
  font-size: 3.5rem;
  margin: 5rem 0;
  position: relative;

  ${mobile({fontSize:'2.5rem', margin: '2rem 0'})}


  &::after {
    content: "";
    width: 4rem;
    height: 8px;
    background-color: #d7950c;
    position: absolute;
    border-radius: 5px;
    left: calc(50% - 1.5rem);
    top: 100%;
  }
`;

const Content = styled.p`
  font-size: 1.2rem;
  max-width: 100%;
  margin: auto;
  text-align: center;

  ${mobile({fontSize:'0.9rem'})}

`;

const FormContainer = styled.div`
  width: 90%;
  margin: auto;
  padding: 2rem 4rem 1rem 1rem;
  background-color: black;
  text-align: right;

  ${mobile({ padding: 0 })}


`;

const Form = styled.form``;

const FormInput = styled.div`
  text-align: left;
`;

const Label = styled.label`
  font-size: 1.4rem;
  margin: 0 2rem;

  ${largephone({margin: '0 1rem'})}
`;

const Input = styled.input`
  width: 90%;
  padding: 1rem;
  border-radius: 5px;
  font-size: 1rem;
  margin: 0rem 2rem;
  border: none;
  line-height: 1rem;

  &::placeholder {
    font-family: "Baloo Bhai 2", cursive;
    font-size: 1rem;
    line-height: 1rem;
  }

  ${largephone({margin: '0 0.5rem'})}
  ${mobile({margin: '0 0.5rem', padding: '0.5rem', width: '85%'})}
`;

const TextArea = styled.textarea`
  width: 90%;
  padding: 1rem;
  border-radius: 5px;
  font-size: 1rem;
  margin: 0rem 2rem;
  line-height: 1rem;
  border: none;
  resize: none;

  &::placeholder {
    font-family: "Baloo Bhai 2", cursive;
    font-size: 1rem;
    line-height: 1rem;
  }

  ${largephone({margin: '0 0.5rem'})}
  ${mobile({margin: '0 0.5rem', padding: '0.5rem', width: '85%'})}



`;

const Button = styled.button`
  background-color: #d7950c;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 0.8rem 4.5rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;

  ${mobile({padding: '0.8rem 3.5rem', marginRight: '1.25rem'})}

`;

const Contact = () => {
 
  return (
    <Container id="contact" >
      <MainContainer data-aos='zoom-in'>
        <Header >
          <Heading>CONTACT</Heading>

          <Content>
            Feel free to Contact me by submitting the form below and I will get
            back to you as soon as possible.
          </Content>
        </Header>
        <FormContainer>
          <Form>
            <FormInput>
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                id="name"
                placeholder="Enter Your Name"
                required
              />
            </FormInput>
            <FormInput>
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                id="email"
                placeholder="Enter Your Email"
                required
              />
            </FormInput>
            <FormInput>
              <Label htmlFor="message">Message</Label>
              <TextArea
                placeholder="Enter Your Message"
                id="message"
                required
                color="30"
                rows="10"
              ></TextArea>
            </FormInput>
            <Button type="submit">SUBMIT</Button>
          </Form>
        </FormContainer>
      </MainContainer>
    </Container>
  );
};

export default Contact;

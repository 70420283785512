import React from 'react'
import './newpage.css'

const NewPage = () => {
  return (
    <div className='new-page'>

    <h1>Hello world</h1>


    </div>
  )
}

export default NewPage
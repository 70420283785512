import React, { useState } from "react";
import styled from "styled-components";

const StyledBurger = styled.div`
  width: 2rem;
  height: 1.6rem;
  position: inherit;
  top: 15px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  cursor: pointer;

  div {
    width: 2rem;
    height: 0.30rem;
    background-color: white;
    border-radius: 10px;
    transition: all 0.3s linear;
    transform-origin: 1px;

    &:nth-child(1){
        transform: ${({open}) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2){
        transform: ${({open}) => open ? 'translateX(200%)' : 'translateX(0)'};
        opacity: ${({open}) => open ? 0 : 1}
    }
    &:nth-child(3){
        transform: ${({open}) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }

  @media (min-width: 601px) {
    display: none;
  }
`;

const MenuItems = styled.div`
  width: 100%;
  min-height: 20vh;
  text-align: right;
  position: absolute;
  top: 5rem;
  padding-right: 1rem;
  right: 0rem;
  background: #100d08;
  border-bottom: 2px solid #d7950c;

  h2 {
    font-size: 1rem;
  }

  a{
    text-decoration: none;
    color: white;
  }

`;

const Hamburg = () => {
  const [open, setOpen] = useState(false);
  const setOpenHandler = () => {
    setOpen(!open);
  };


  return (
    <>
      <StyledBurger open={open} onClick={setOpenHandler}>
        <div />
        <div />
        <div />
      </StyledBurger>
      {open &&<MenuItems>
        <h2><a href="#home" onClick={setOpenHandler}>HOME</a></h2>
        <h2><a href="#about" onClick={setOpenHandler}>ABOUT</a></h2>
        <h2><a href="#projects" onClick={setOpenHandler}>PROJECTS</a></h2>
        <h2><a href="#contact" onClick={setOpenHandler}>CONTACT</a></h2>
      </MenuItems>}
      
    </>
  );
};

export default Hamburg;
